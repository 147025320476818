import React from "react";

const Work = () => {
  const workInfoData = [
    {
      // image: PickMeals,
      title: "Blockchain app consultation",
      text: "We help business leaders recognize how blockchain can be a perfect fit to their business model. Our blockchain consulting services and app development solutions include tailor made apps built on high functioning platforms like Hyperledger, EOS, and Stellar.",
    },
    {
      // image: ChooseMeals,
      title: "MVP development",
      text: "We believe in testing the waters before launching a full-fledged DApp. So, our Blockchain solutions development revolves around the building and launch of a DApp MVP.",
    },
    {
      // image: DeliveryMeals,
      title: "Smart contracts development",
      text: "Our team of smart contract developers craft immutable and accurate smart contracts which make the business ecosystem conflict-free.",
    },{
      // image: PickMeals,
      title: "End-to-end DApp solutions",
      text: "From ideation, design, coding to the final launch, we handle your complete DApp development process from start to finish through our expertise in Blockchain decentralized app development.",
    },
    {
      // image: ChooseMeals,
      title: "Custom blockchain solutions",
      text: "We offer customized Blockchain solutions to integrate with your existing mobile apps, making them Dapps. We also specialize in architecting customized Blockchain software development that aligns with your workflow.",
    },
    {
      // image: DeliveryMeals,
      title: "Crypto wallet development",
      text: "As part of our Blockchain wallet app development services, we create crypto wallet Dapps that hold hundreds of cryptocurrencies, while carrying information related to your wallet balance and purchase history.",
    },{
      // image: DeliveryMeals,
      title: "Hyperledger based solutions",
      text: "Using hyper ledger, we develop Blockchain Applications that are scalable, immutable, trusted, protected, and support a range of plug-ins.",
    },
    {
      // image: DeliveryMeals,
      title: "Permissioned (private) blockchain",
      text: "We develop enterprise-grade distributed ledger and codebase, which restricts the participation in the open source distributed ledger.",
    },
    {
      // image: DeliveryMeals,
      title: "Crypto-token-development",
      text: "Our team of blockchain developers also offers the service of digital tokenization. The tokens we create can be used to represent asset values to be traded as cryptocurrency, and loyalty points, instead of cash.",
    },
    {
      // image: DeliveryMeals,
      title: "NFT marketplace development",
      text: "Our blockchain development services also include building decentralized NFT marketplaces to manage NFT trading, bidding and selling digital assets. We also launch cross-chain marketplaces that help with multichain NFTs in business.",
    }
  ];
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <h1 className="primary-heading">Our Expertise</h1>
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container">
              <img src={data.image} alt="" />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
