import React, { useState } from "react";

const Navbar = () => {
  return (
    <nav>
      <div className="nav-logo-container">
        <h2 className="primary-subheading">The unicorn Ventures</h2>
      </div>
    </nav>
  );
};

export default Navbar;
