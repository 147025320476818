import React from "react";
import AboutBackgroundImage from "../Assets/design shape.png";

const About = () => {
  return (
    <div className="about-section-container">
      <div className="about-background-image-container">
        {/* <img src={AboutBackground} alt="" /> */}
      </div>
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        {/* <p className="primary-subheading">About</p> */}
        <h2 className="primary-heading">About Us</h2>
        <p className="primary-text">
        the unicorn ventures is a technology partner for all-sized businesses trusted by leading organizations worldwide. We are solely focused on producing smart cloud architecture, strong backend, viral WebGL, flexible mobile apps, next-generation Web3 solutions, and sophisticated digital twins. Our experienced industry-focused engineers create excellent digital solutions that meet each client's business objectives.
        </p>
        <p className="primary-text">
        We keep track of our client's success to make it even stronger and spread the pattern on future software development in each particular domain.
        </p>
        <div className="about-buttons-container">
        </div>
      </div>
    </div>
  );
};

export default About;
